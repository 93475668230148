import request from '@/utils/request';

// 保存报价
export function saveQuote(data) {
  return request({
    url: '/quote/save',
    method: 'post',
    data
  });
}

// 获取报价列表
export function getQuoteList(data) {
  return request({
    url: '/quote/list',
    method: 'get',
    params: data
  });
}

// 获取报价详情
export function getQuoteDetails(quoteId) {
  return request({
    url: `/quote/details/${quoteId}`,
    method: 'get'
  });
}

// 删除报价
export function deleteQuote(quoteId) {
  return request({
    url: `/quote/delete/${quoteId}`,
    method: 'post'
  });
}

// 修改报价状态
export function updateQuoteStatus(quoteId, status) {
  return request({
    url: `/quote/update/status/${quoteId}/${status}`,
    method: 'post'
  });
}

// 获取参与报价列表
export function getQuoteJoinList(quoteId) {
  return request({
    url: `/quote/join/list/${quoteId}`,
    method: 'get'
  });
}

// 选中报价
export function selectQuote(quoteId, joinId) {
  return request({
    url: `/quote/select/join/${quoteId}/${joinId}`,
    method: 'post'
  });
}
