<template>
  <div class="main">
    <h1 class="h1">{{$t('MyCompetition')}}</h1>
    <tabs v-model="activeName">
      <tab-pane :label="$t('Participated Competition')" name="all"></tab-pane>
    </tabs>

    <div class="table">
      <table>
        <thead>
        <tr>
          <!--          <th>{{$t('Version')}}</th>-->
<!--          <th></th>-->
          <th>{{$t('Competition Name')}}</th>
          <th class="is-sortable" :class="sortClassName('startDate')" @click="sortable('date')">
            <span class="vam">{{$t('Start Date')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
              </span>
          </th>
          <th class="is-sortable" :class="sortClassName('endDate')" @click="sortable('date')">
            <span class="vam">{{$t('Deadline date')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
              </span>
          </th>
          <th class="is-sortable" :class="sortClassName('status')" @click="sortable('browse')">
            <span class="vam">{{$t('Status')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
              </span>
          </th>
          <th>{{$t('Last Update')}}</th>
          <th>{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody>
<!--        <tr v-for="(item,index) of list" :key="item.quoteId">-->
          <tr v-for="(item) of list" :key="item.quoteId">
          <!--          <td class="relative">-->
          <!--            V1-->
          <!--            <i class="el-icon-arrow-down"></i>-->
          <!--            <i class="icons i-new"></i>-->
          <!--          </td>-->
<!--          <td><img width="100vw" :src="item.competitionPhotoURL"></td>-->
          <td>
            <div class="title">{{item.competitionTitle}}</div>
          </td>
          <td>{{item.isStatus!=='edit'&&item.startDate|formatDate('yyyy/MM/dd')}}</td>
          <td>{{item.endDate|formatDate('yyyy/MM/dd')}}</td>
          <td>{{$t('CompetitionStatus')[item.competitionStatus]}}</td>
          <td>{{item.lastUpdateDate|formatDate('yyyy/MM/dd hh:mm:ss')}}</td>
          <td>
            <a style="padding: 0 3px 0 3px" :href="`/competition/detail/${item.competitionID}`"><i class="fas fa-search"></i></a>
            <a style="padding: 0 3px 0 3px" :href="`competition/add?id=${item.productID || ''}&competitionID=${item.competitionID}`"><i class="fas fa-edit"></i></a>
<!--            <router-link class="btn-item m-r-8"-->
<!--                         target="_blank"-->
<!--                         :title="$t('Modify')"-->
<!--                         :to="{name:'UserQuoteEdit',query:{id:item.quoteId}}"-->
<!--                         v-if="['edit','issue'].includes(item.isStatus)">-->
<!--              <img src="../../assets/images/icon/pen.png"/>-->
<!--            </router-link>-->
<!--            <router-link class="btn-item m-r-8"-->
<!--                         target="_blank"-->
<!--                         :title="$t('View Quote')"-->
<!--                         :to="{name:'UserQuoteDetail',params:{id:item.quoteId}}"-->
<!--                         v-if="['issue','over','end'].includes(item.isStatus)">-->
<!--              <img src="../../assets/images/icon/review.png"/>-->
<!--            </router-link>-->
<!--            <a class="btn-item" href="javascript:;" :title="$t('Delete')" @click="deleteQuote(index,item.quoteId)" :disabled="item.quoteCount !== 0">-->
<!--              <img src="../../assets/images/icon/ashcan.png"/>-->
<!--            </a>-->
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>

  </div>
</template>

<script>
import Empty from '../../components/Empty';
import Tabs from '../../components/Tabs/Tabs';
import TabPane from '../../components/Tabs/TabPane';
import table from '../../mixins/table';
import {deleteQuote, updateQuoteStatus} from '../../api/quote';
import {scrollToY} from '../../utils';
import {getUserCompetitionList} from '../../api/competition';

export default {
  name: 'MyCompetition',
  data() {
    return {
      activeName: 'all',
      perPage: 10,
      list: [],
      totalPages: 1,
      isEmpty: false
    };
  },
  components: {Empty, Tabs, TabPane},
  mixins: [table],
  created() {
    this.getList();
  },
  methods: {
    // 获取消息列表
    getList(page = 1) {
      const data = {page, perPage: this.perPage};
      getUserCompetitionList().then(res => {
        const {value} = res;
        const list = value.list || [];
        this.list = list;
        this.totalPages = value.totalPages;
        this.isEmpty = data.page === 1 && !this.list.length;
        this.$nextTick(() => {
          scrollToY();
        });
      });
    },
    changePage(page) {
      this.getList(page);
    },
    handleSizeChange(val) {
      this.perPage = val;
      this.getList();
    },
    // 修改发布状态
    changeStatus(command, instance) {
      const {index, quoteId, status, message = this.$t('confirm.over')} = command;
      this.$msgbox({
        title: this.$t('Prompt'),
        message,
        center: true,
        showClose: false,
        showCancelButton: true,
        lockScroll: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            updateQuoteStatus(quoteId, status).then(res => {
              instance.confirmButtonLoading = false;
              done();
              this.list[index].isStatus = status;
            }).catch(() => {
              instance.confirmButtonLoading = false;
            });
          } else {
            done();
          }
        }
      }).then(action => {
      }).catch(() => {
      });
    },
    // 删除报价
    deleteQuote(index, quoteId) {
      this.$msgbox({
        title: this.$t('Prompt'),
        message: this.$t('confirm.delete'),
        center: true,
        showClose: false,
        showCancelButton: true,
        lockScroll: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            deleteQuote(quoteId).then(res => {
              instance.confirmButtonLoading = false;
              done();
              this.list.splice(index, 1);
              this.$message.success('刪除成功!');
              if (!this.list.length) {
                this.getList();
              }
            }).catch(() => {
              instance.confirmButtonLoading = false;
            });
          } else {
            done();
          }
        }
      }).then(action => {
      }).catch(() => {
      });
    }
  }
};
</script>

<style scoped lang="less">
@import '../../assets/style/table';
.main{
  .actions{margin-top:20px;}
  .table{
    th,
    td{padding-left:8px;padding-right:8px;}
    .title{width:90px;}
  }
  .i-plus{width:16px;height:16px;margin-right:4px;vertical-align:middle;background-image:url(../../assets/images/icon/plus-circle.png);}
  .i-new{position:absolute;top:6px;right:10px;width:14px;height:8px;background-image:url(../../assets/images/icon/new.png);}
}
</style>
